import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./use-cases@gen.scss";

const GeneratedProductUseCases = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>Use Case - PagerDuty正規代理店 - 株式会社Digital Stacks</title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="部門ごとのPagerDuty活用法をご紹介しています。
DevOps、ITの運用、セキュリティの運用、サポート、Business Operations、生産工程の運用など。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="部門ごとのPagerDuty活用法をご紹介しています。
DevOps、ITの運用、セキュリティの運用、サポート、Business Operations、生産工程の運用など。"
        />

        <meta
          property="og:title"
          content="Use Case - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="部門ごとのPagerDuty活用法をご紹介しています。
DevOps、ITの運用、セキュリティの運用、サポート、Business Operations、生産工程の運用など。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/use-cases/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/use-cases/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="Use Cases"
        overview="部門ごとのPagerDuty活用法をご紹介しています。<br />
DevOps、ITの運用、セキュリティの運用、サポート、Business Operations、生産工程の運用など。"
      />

      <div className="useCasesWrapper">
        <div className="useCasesContainer">
          <div className="hero-container">
            <div className="center-2">
              <h3 className="center-title">部門ごとのPagerDuty活用法</h3>
              <p className="center-para">
                PagerDutyにより、様々なユースケースにおいて、
                生産性の向上や脅威の低減を実現します。
                <br />
                部門毎の革新的な変革をご覧ください。
              </p>
            </div>
            <div className="hero-category-container my-[30px]">
              <div className="hero-category">
                <div className="hero-category-title">
                  <span>DevOps</span>
                </div>
                <div className="hero-category-img-container">
                  <img
                    src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/icn_devops_d622c238e3.png"
                    alt="DevOps"
                  />
                </div>
              </div>
              <div className="hero-category">
                <div className="hero-category-title">
                  <span>ITの運用</span>
                </div>
                <div className="hero-category-img-container">
                  <img
                    src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/icn_itops_0ce03d64b0.png"
                    alt="ITの運用"
                  />
                </div>
              </div>
              <div className="hero-category">
                <div className="hero-category-title">
                  <span>セキュリティの運用</span>
                </div>
                <div className="hero-category-img-container">
                  <img
                    src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/icn_secops_7632b96c1a.png"
                    alt="セキュリティの運用"
                  />
                </div>
              </div>
              <div className="hero-category">
                <div className="hero-category-title">
                  <span>サポート</span>
                </div>
                <div className="hero-category-img-container">
                  <img
                    src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/icn_supportops_3757951dec.png"
                    alt="サポート"
                  />
                </div>
              </div>
              <div className="hero-category">
                <div className="hero-category-title">
                  <span>Business Operations</span>
                </div>
                <div className="hero-category-img-container">
                  <img
                    src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/icn_bizops_8e61a74e21.png"
                    alt="Business Operations"
                  />
                </div>
              </div>
              <div className="hero-category">
                <div className="hero-category-title">
                  <span>生産工程の運用</span>
                </div>
                <div className="hero-category-img-container">
                  <img
                    src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/icn_bizops_8e61a74e21.png"
                    alt="生産工程の運用"
                  />
                </div>
              </div>
            </div>
            <div className="hero-btn-container">
              <div className="hero-btn">
                <p>PagerDutyの運用マネージメントプラットフォーム</p>
              </div>
            </div>
          </div>
          <div className="sections-container">
            <div className="text-left-1">
              <div className="text-part">
                <h3 className="left-title">DevOps</h3>
                <p className="text-left-para">
                  開発者には、自分の構築したものに対する責任が伴います。自動インシデントソリューションが優れたソフトウェアの提供を可能にします。
                </p>
                <ul className="list-container">
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    フルスタックの可視化より、関わる問題をいち早く検知。
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    まさに必要とするコンテクストで問題に集中。
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    フルスタックの可視化より、関わる問題をいち早く検知。
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    ワークフローを自動化して手動の作業を最小化することで、問題を迅速に解決。
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    すでにお使いのツールの統合
                  </li>
                </ul>
                <div className="left-cta">
                  <a href="/product/developers/">詳しくはこちら</a>
                </div>
              </div>
              <div className="img-container">
                <iframe
                  width="100%"
                  height={300}
                  src="https://www.youtube.com/embed/OQB0G6YwSyE"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <span className="text-[12px] md:text-[14px]">
                  PagerDutyのおかげでTwilioでは、お客様対応の質と速度が上がりました
                </span>
              </div>
            </div>
            <div className="text-right-1">
              <div className="text-part">
                <h3 className="left-title">ITの運用</h3>
                <p className="text-left-para">
                  ITマネージャーの抱える問題を当社が解決します。混乱をなくしより重要な事柄に時間を費やしましょう。
                </p>
                <ul className="list-container">
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    適切な情報と強力な自動化でより迅速な解決を促す
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    顧客が問題の影響を受ける前に問題を防ぐため、より生産的な時間を確保
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    プロセスの一貫性を保ちながら、動員と迅速な応答を最適化
                  </li>
                </ul>
                <div className="left-cta">
                  <a href="/product/it-operations/">詳しくはこちら</a>
                </div>
              </div>
              <div className="img-container">
                <iframe
                  width="100%"
                  height={300}
                  src="https://www.youtube.com/embed/OF7YZ2JX0JA"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <span className="text-[12px] md:text-[14px]">
                  Oktaでは、PagerDutyを利用した迅速なインシデント管理を提供することで、100%のシステム稼働時間を実現
                </span>
              </div>
            </div>
            <div className="text-left-1">
              <div className="text-part">
                <h3 className="left-title">セキュリティの運用</h3>
                <p className="text-left-para">
                  大勢の人が接続されている状態で、エンジニアは前兆を一つたりとも見逃すわけにはいきません。セキュリティの脅威を迅速に解決し、先を見越した予防を。
                </p>
                <ul className="list-container">
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    フルスタックのビューと、関連するアラートを自動グループ化により、ノイズ中に潜む前兆を特定
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    適切な専門家を素早く自動で手配して、クリティカルなセキュリティ管理を実現
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    過去に起きたセキュリティ違反を学習して、さらにセキュアなサービスへ
                  </li>
                </ul>
                <div className="left-cta">
                  <a href="/casestudy/signal-sciences/">詳しくはこちら</a>
                </div>
              </div>
              <div className="img-container text-center">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Use_Cases/signalsciences_6b08bfca75.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672997828865&tr=w-480,q-100,f-webp"
                  alt="セキュリティの運用"
                />
                <p className="text-[12px] md:text-[14px]">
                  PagerDutyの活用によって、当社のセキュリティ体制を万全にし
                  セキュリティの事故により早くより着実に対応することができています。
                  見過ごされることがないと安心できます。
                  <br />— Zane Lackey氏、CSO、Signal Sciences
                </p>
              </div>
            </div>
            <div className="text-right-1">
              <div className="text-part">
                <h3 className="left-title">サポート</h3>
                <p className="text-left-para">
                  マネージャーをサポートし、お客様に満足いただくための力をチームにつけます。チームの規模に関係なく365日24時間体制で強力なサポートを提供。
                </p>
                <ul className="list-container">
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    関連するチケットのグループ化とルーティングの自動化でチケットクローズまでの時間を低減
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    Live Call
                    Routingによって、オンコールの担当者に誰でもリアルタイムで連絡が可能
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    インシデント管理情報を最新に維持して顧客の信頼を構築
                  </li>
                </ul>
              </div>
              <div className="img-container">
                <iframe
                  width="100%"
                  height={300}
                  src="https://www.youtube.com/embed/KahH5oSTBQg"
                  title="YouTube video player"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <span className="text-[12px] md:text-[14px]">
                  PagerDutyの導入で、顧客へ提供するするサービスが期待を超えるものに
                </span>
              </div>
            </div>
            <div className="text-left-1">
              <div className="text-part">
                <h3 className="left-title">Business Operations</h3>
                <p className="text-left-para">
                  いかなる状況でも適切な人員をリアルタイムで動員。
                </p>
                <ul className="list-container">
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    顧客への適切なアクションを取るためにビジネスステークホルダーが確実に関与
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    どんな前兆も自動的に、編成された部門間協力型の対応へ落とし込み
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    組み込まれたコミュニケーションのベストプラクティスで直ちに動員
                  </li>
                </ul>
              </div>
              <div className="img-container text-center">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Use_Cases/Pic_Nic_ec67b0dc88.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672997828864&tr=w-160,q-100,f-webp"
                  alt="Business Operations"
                />
                <p className="text-[12px] md:text-[14px]">
                  PagerDutyは、サプライチェーンにおける初期の時点の問題を
                  特定するのに役立っており、問題を軽減し顧客への影響を未然に防ぐことを
                  可能にしてくれます
                  <br />— Daniel Gebler氏、CTO、Picnic
                </p>
              </div>
            </div>
            <div className="text-right-1">
              <div className="text-part">
                <h3 className="left-title">生産工程の運用</h3>
                <p className="text-left-para">
                  計画外のダウンタイムを低減し、ハードウェアとソフトウェアの両方における運用効率とシステムパフォーマンスを向上させます。
                </p>
                <ul className="list-container">
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    ITとOT(Operational
                    Tehnologies)を融合し、リアルタイムで連携した対応を編成
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    オペレーターへ適切なインサイトを提供することで、応答と解決の時間を低減
                  </li>
                  <li className="list-items">
                    <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/ul_list_application_2c26180dd0.png" />
                    継続的な学習による生産性の向上と予防保守
                  </li>
                </ul>
              </div>
              <div className="img-container text-center">
                <img
                  src="https://ik.imagekit.io/tcqno3mrr/PagerDuty/Product/Use_Cases/industrial_ops_d26696fb45.png?ik-sdk-version=javascript-1.4.3&updatedAt=1672997828986&tr=w-720,q-100,f-webp"
                  alt="生産工程の運用"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductUseCases;
